.footer {
  --footerItemHeight: 2rem;
}

.footer {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  color: var(--colorGray6);
  background-color: var(--colorTan1);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.linklist {
  display: flex;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 0.75rem;
}

.linklist > li {
  display: inline-block;
  margin: 0 0.5rem;
  line-height: var(--footerItemHeight);
  height: var(--footerItemHeight);
}

.linklist a {
  color: inherit;
  text-decoration: none;
}

.linklist a:hover {
  color: var(--colorAnchorTextHover);
}

.socialLinkList {
  composes: linklist;
  padding-bottom: 1rem;
}

.socialLinkList li {
  padding-right: 0.5rem;
}

.socialLinkList a {
  display: flex;
  align-items: center;
  height: var(--footerItemHeight);
  color: var(--colorTextVeryMuted);
}

.legalLinklist {
  composes: linklist;
}

@media (--viewportTabletMin) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }

  .section {
    flex-direction: row;
    padding-bottom: 0;
  }

  .socialLinkList {
    padding-right: 2rem;
    padding-bottom: 0;
  }

  .socialLinkList li:first-child {
    margin-left: 0;
  }

  .legalLinklist li:last-child {
    margin-right: 0;
  }
}
